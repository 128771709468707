import styled from "styled-components";
// import flowers from "../../images/Banner.jpg";

export const Bgd = styled.div`
  background-size: cover;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  flex: 1 1 auto;
`;
