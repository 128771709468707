import styled from "styled-components";

export const MenuNav = styled.nav``;

export const List = styled.ul`
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
  /* width: 100%; */
  /* height: 100%; */
`;
